import { Component, Inject, OnInit } from '@angular/core';
import { Meta } from '@angular/platform-browser';

import { OktaInterfaceService } from '@shure/cloud/shared/okta/data-access';
import { BreakpointService } from '@shure/cloud/shared/services/media-breakpoints';
import { APP_ENVIRONMENT, AppEnvironment } from '@shure/cloud/shared/utils/config';
import { LanguagesService } from '@shure/shared/angular/utils/i18n';

import { environment } from '../environments/environment';

@Component({
	selector: 'sh-app-root',
	templateUrl: './app.component.html',
	styleUrls: ['./app.component.scss']
})
export class AppComponent implements OnInit {
	public appVersion: string;
	public clientVersion: string | undefined;

	public readonly isSmallDevice = this.breakpointService.isLteSmall;

	constructor(
		private readonly languageService: LanguagesService,
		private metaTagService: Meta,
		public oktaIntf: OktaInterfaceService,
		private breakpointService: BreakpointService,
		@Inject(APP_ENVIRONMENT) private appEnv: AppEnvironment
	) {
		this.appVersion = this.appEnv.appVersion;
		this.clientVersion = this.appEnv.clientVersion;
	}

	public ngOnInit(): void {
		/* no-op comment to trigger pipelin build */
		this.languageService.getPreferredCultureCode();
		if (environment.production) {
			this.metaTagService.addTags([{ name: 'robots', content: 'index' }]);
		} else {
			this.metaTagService.addTags([{ name: 'robots', content: 'noindex, nofollow' }]);
		}
	}
}
