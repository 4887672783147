import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';

import { OktaInterfaceService } from '@shure/cloud/shared/okta/data-access';

// This guard is to protect the signin page, and only allow routing to that page
// if the user is NOT signed in. If the user tries to navigate to the signin page
// and is already logged in, they will be re-routed to the / route.

@Injectable({
	providedIn: 'root'
})
export class SigninGuard {
	constructor(
		private oktaIntfService: OktaInterfaceService,
		private router: Router
	) {}

	public canActivate(): Observable<boolean> | boolean {
		return this.oktaIntfService.$isUserAuthenticated.pipe(
			map((isSignedIn: boolean) => {
				if (!isSignedIn) {
					return true;
				}
				this.router.navigate(['/']);
				return false;
			})
		);
	}
}
