import { NgModule } from '@angular/core';
import { MatIconModule, MatIconRegistry } from '@angular/material/icon';

/**
 * Global provider for material icons using shure fonts for the application.
 *
 * **This will contain icons only used by this application and should not be included within
 * other contexts.**
 */
@NgModule({
	imports: [MatIconModule]
})
export class MaterialIconsModule {
	constructor(private readonly iconRegistry: MatIconRegistry) {
		this.iconRegistry.registerFontClassAlias('prism-mat', 'prism-mat-sharp');
		this.iconRegistry.setDefaultFontSetClass('prism-mat-sharp');
	}
}
