import { TranslocoService } from '@jsverse/transloco';

import { DeviceModel } from '@shure/shared/angular/data-access/system-api/models';
import {
	DeviceBehaviorPlugin,
	DeviceImage,
	DeviceImageBehaviorPlugin
} from '@shure/shared/angular/device-behavior/core';

/**
 * `P300DeviceBehaviorPluginBase` provides an base for common feature description across applications,
 * fx. designer, cui etc.
 *
 * **Do not put app specifics into this class. If app specific setup is required create a
 * plugin for the app.**
 */
export abstract class P300DeviceBehaviorPluginBase implements DeviceBehaviorPlugin, DeviceImageBehaviorPlugin {
	public readonly models: DeviceModel[] = [DeviceModel.P300];

	constructor(private readonly transloco: TranslocoService) {}

	public getImage(model: DeviceModel): DeviceImage | undefined {
		switch (model) {
			case DeviceModel.P300:
				return {
					lightsBackground: 'missing-in-device-plugin',
					live: 'device-bridge.svg',
					virtual: 'device-p300-line.svg',
					liveBack: 'missing-in-device-plugin',
					virtualBack: 'missing-in-device-plugin'
				};
			default:
				return undefined;
		}
	}
}
