import { NgModule } from '@angular/core';
import { RouterModule, Route } from '@angular/router';
import { OktaAuthGuard, OktaAuthModule, OktaCallbackComponent } from '@okta/okta-angular';

import { appEnvironmentGuard } from '@shure/cloud/shared/guards/app-environment-guard';
import { SigninGuard, SignInComponent } from '@shure/cloud/shared/okta/feature-okta-signin';

const appRoutes: Route[] = [
	{
		path: '',
		redirectTo: 'devices',
		pathMatch: 'full'
	},
	{
		path: 'signin',
		component: SignInComponent,
		canActivate: [SigninGuard]
	},

	{
		path: 'devices',
		loadChildren: () =>
			import('@shure/cloud/device-management/devices/feature-inventory').then((m) => m.InventoryMainModule),
		canActivate: [OktaAuthGuard]
	},
	{
		path: 'pending-devices',
		loadChildren: () =>
			import('@shure/cloud/device-management/devices/feature-pending').then((m) => m.PendingDevicesModule),
		canActivate: [OktaAuthGuard]
	},
	{
		path: 'account',
		loadComponent: () => import('../components/my-account/my-account.component').then((m) => m.MyAccountComponent),
		canActivate: [OktaAuthGuard]
	},
	{
		path: 'signin/callback',
		component: OktaCallbackComponent
	},
	{
		path: 'settings',
		loadComponent: () =>
			import('../components/settings/settings.component').then((m) => m.DeviceSettingsWrapperComponent),
		canActivate: [OktaAuthGuard, appEnvironmentGuard(['showUserSettings'])]
	},
	// ** is the "page not found" route.
	// Try to go to /devices. If the user isn't logged in, the auth guard
	// will redirect them to the Signin Page.
	{
		path: '**',
		redirectTo: 'devices',
		pathMatch: 'full'
	}
];

@NgModule({
	imports: [
		RouterModule.forRoot(appRoutes, {
			initialNavigation: 'enabledBlocking',
			useHash: false // must be false for okta signin/callback to route.
		}),
		OktaAuthModule
	],
	exports: [RouterModule]
})
export class AppRoutingModule {}
