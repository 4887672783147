import { Injectable } from '@angular/core';
import { TranslocoService } from '@jsverse/transloco';

import { AniusbDeviceBehaviorPluginBase } from './aniusb.device-behavior';

@Injectable({ providedIn: 'root' })
export class AniusbDesignerDeviceBehaviorPlugin extends AniusbDeviceBehaviorPluginBase {
	constructor(transloco: TranslocoService) {
		super(transloco);
	}
}
