import { Injectable } from '@angular/core';
import { TranslocoService } from '@jsverse/transloco';

import { Mxw1xDeviceBehaviorPluginBase } from './mxw1x.device-behavior';

@Injectable({ providedIn: 'root' })
export class Mxw1xDesignerDeviceBehaviorPlugin extends Mxw1xDeviceBehaviorPluginBase {
	constructor(transloco: TranslocoService) {
		super(transloco);
	}
}
