import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
// TODO - remove use of flex-layout package - update html/css to use flexBox
import { FlexLayoutModule } from '@angular/flex-layout';
import { RouterModule, Routes } from '@angular/router';

import { SignInComponent } from './okta-signin-main.component';

const routes: Routes = [{ path: '', component: SignInComponent }];

@NgModule({
	imports: [CommonModule, RouterModule.forChild(routes), FlexLayoutModule],
	declarations: [SignInComponent],
	exports: [SignInComponent]
})
export class OktaSignInModule {}
