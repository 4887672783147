<a class="shure-cloud-logo-container" id="sh-device-manager-shure-cloud-logo-container" [href]="shureCloudUri">
	<img
		class="shure-cloud-image"
		id="sh-device-manager-shure-cloud-logo"
		src="../../../../../../assets/images/shure_logo_icon.svg"
	/>
	<span class="shure-cloud-text" id="sh-device-manager-shure-cloud-text">ShureCloud</span>
</a>

<mat-divider>&nbsp;</mat-divider>

<div class="device-management-header" id="sh-device-manager-app-name-container" *transloco="let t">
	<span>{{ t('cloud.device-management.name') }}</span>
</div>

<ng-container *transloco="let t">
	<prism-nav hasSecondaryNav="false" secondary="true" accent="true">
		<prism-nav-group>
			<prism-nav-button
				*ngFor="let navItem of navItems"
				selected="{{ navItem.selected }}"
				disabled="{{ navItem.disabled }}"
				id="{{ navItem.buttonId }}"
				[attr.data-testid]="navItem.buttonId"
				routerLink="{{ navItem.route }}"
			>
				<prism-icon slot="icon" icon="{{ navItem.icon }}"></prism-icon>
				<span
					>{{ t(navItem.label) }}
					<ng-container *ngIf="navItem.count$ | async as count">
						<span id="{{ navItem.buttonId }}-count">({{ count }})</span>
					</ng-container>
				</span>
			</prism-nav-button>
		</prism-nav-group>
	</prism-nav>
</ng-container>
